<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item label="签约进度" prop="qstate">
      <a-select v-model="form.qstate" placeholder="无进度/定金/签约">
        <a-select-option value="1"> 无进度 </a-select-option>
        <a-select-option value="2"> 定金 </a-select-option>
        <a-select-option value="3"> 签约 </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="定金(元)">
      <a-input v-model="form.qdeposit" />
    </a-form-model-item>
    <a-form-model-item label="预付款">
      <a-input v-model="form.qprepayment" />

      <a-checkbox
        @change="changeCheckbox"
        :checked="form.qincludDeposit == 0 ? false : true"
      >
        含定金
      </a-checkbox>
      <!-- <a-checkbox-group v-model="form.qincludDeposit">
        <a-checkbox :value="1" name="type"> 含定金 </a-checkbox>
      </a-checkbox-group> -->
      <!--    @blur="
          () => {
            $refs.name.onFieldBlur();
          }
        " -->
    </a-form-model-item>
    <a-form-model-item label="合同编号">
      <a-select v-model="form.qcno" show-search placeholder="请选择合同编号">
        <a-select-option
          v-for="el in ContractNumberList"
          :key="el.conly"
          :value="el.conly"
        >
          {{ el.conly }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
      <a-button type="primary" @click="onSubmit"> 保存 </a-button>
      <a-button style="margin-left: 10px" @click="onClose"> 取消 </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import {
  GetContractList,
  AddQuotation,
  GenerateContractQuotation,
} from "@/api/apiJF/quotation";
export default {
  props: {
    record: {
      type: String,
    },
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      form: {
        qstate: "", //签约进度
        qdeposit: "", //定金
        qprepayment: "", //预付款
        qincludDeposit: 0, //是否含定金
        qcno: "", //合同编号
      },
      rules: {
        qstate: [
          {
            required: true,
            message: "Please select Activity zone",
            trigger: "change",
          },
        ],
      },
      ContractNumberList: [],
    };
  },
  created() {
    console.log("this.record", JSON.parse(this.record));
    let record = JSON.parse(this.record);
    // this.form ;
    this.GetContractNumber(record.qbcId);
  },
  mounted() {},
  methods: {
    // 获取合同编号列表
    GetContractNumber(cbcId) {
      let queryParam = {
        cbcId: cbcId,
      };
      let orderParam = Object.keys(queryParam);
      console.log("orderParam", orderParam);

      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetContractList(params).then((res) => {
        // console.log("合同列表", res);
        // return res.data;
        this.ContractNumberList = res.data;
      });
    },
    // // 获取转化进度--生成签约报价
    // GenerateContractQuotation(record) {
    //   GenerateContractQuotation(record).then((res) => {});
    // },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //   alert("submit!");
          //   console.log("this.form", this.form);
          let record = JSON.parse(this.record);
          //   for (let el in record) {
          //     this.form[el] = record[el];
          //   }
          GenerateContractQuotation(record).then((res) => {
            if (res.code == 200) {
              console.log("res", res.data);
              let value = res.data;
              for (let el in this.form) {
                value[el] = this.form[el];
              }
              // value.qcno = this.form.qcno;
              console.log("value", value);
              AddQuotation(value).then((res) => {
                if (res.code == 200) {
                  this.$message.success("转化进度成功");
                  this.onClose();
                } else {
                  this.$message.error(res.msg);
                }
              });
            }
          });
          console.log(" this.form", this.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    changeCheckbox(e) {
      //   console.log("changeCheckbox", e.target.checked);
      this.form.qincludDeposit = e.target.checked ? 1 : 0;
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>
